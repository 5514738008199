<template>
  <v-app id="inspire">
    <v-row dense no-gutters>
      <v-col cols="12" md="5" class="d-none d-md-flex">
        <v-img src="@/assets/img/base/bg-register.png" height="100vh" position="bottom center" />
      </v-col>

      <v-col cols="12" md="7" class="d-flex">
        <ValidationObserver v-slot="{handleSubmit}" class="row no-gutters">
          <v-container
            class="col-9 col-sm-7 col-md-7 col-lg-9 d-flex flex-column justify-center py-10"
            tag="form"
            @submit="handleSubmit(registerRequest)"
          >
            <div>{{ $t('account.welcomeBack') }}</div>
            <h2 class="mb-5">{{ $t('account.getStartWithApp') }}</h2>
            <c-input
              v-model="model.firstName"
              :label="$t('shared.firstName')"
              :placeholder="$t('account.enterYourFirstName')"
              :dense="false"
              rules="required"
            />
            <c-input
              v-model="model.lastName"
              :label="$t('shared.lastName')"
              :placeholder="$t('account.enterYourLastName')"
              :dense="false"
              rules="required"
            />
            <c-input
              v-model="model.email"
              :label="$t('shared.email')"
              :placeholder="$t('account.enterYourEmail')"
              :dense="false"
              :rules="{required: !model.mobile, email: true}"
            />
            <mobile-input
              v-model="model.mobile"
              :code.sync="model.countryCode"
              :label="$t('shared.mobile')"
              :placeholder="$t('account.enterYourMobile')"
              :dense="false"
              :rules="{required: !model.email}"
            />
            <c-input
              v-model="model.password"
              :label="$t('shared.password')"
              :placeholder="$t('account.enterYourPassword')"
              :dense="false"
              rules="required"
              append-icon="mdi-eye"
              type="password"
            />

            <c-input
              v-model="model.organizationId"
              :label="$t('organizations.organizationId')"
              :dense="false"
              rules="required|numeric"
            />

            <c-input
              v-model="model.workingSiteId"
              :label="$t('workingSites.workingSiteId')"
              :dense="false"
              rules="required|numeric"
            />

            <v-btn class="mb-3" color="success" @click.prevent="handleSubmit(registerRequest)" :loading="progressing">
              {{ $t('account.submitRegisterRequest') }}
            </v-btn>

            <div>
              {{ $t('account.haveAnAccount') }}

              <router-link to="/login">
                {{ $t('account.loginNow') }}
              </router-link>
            </div>
          </v-container>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import {mapMutations} from 'vuex'
export default {
  name: 'Register',
  data() {
    return {
      progressing: false,
      model: {
        countryCode: null,
        email: null,
        firstName: null,
        lastName: null,
        mobile: null,
        countryCode: 'AU',
        organizationId: null,
        workingSiteId: null
      }
    }
  },
  methods: {
    ...mapMutations({
      setAccount: 'SET_ACCOUNT'
    }),
    async registerRequest() {
      try {
        this.progressing = true
        await this.$api.registerRequestsPub.create(this.model)
        this.$showSuccess(this.$t('account.registrationSended'))
        this.$router.push('/login')
      } catch (err) {
        this.$showError(err)
      } finally {
        this.progressing = false
      }
    }
  }
}
</script>
